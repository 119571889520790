import React, { FC, useCallback, useEffect, useState } from 'react';

import Image from 'common/Image';

import './Video.scss';

const Video: FC<PageContent.VideoType> = ({
  coverImage,
  videoUrl,
  videoAlt,
  playButtonAriaLabel,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const togglePlay = useCallback(() => setIsPlaying(!isPlaying), [isPlaying]);

  useEffect(() => {
    const onScroll = () => setIsPlaying(false);
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const youtubeId = videoUrl.slice(30);
  const youtubeCoverImage = `url(https://img.youtube.com/vi/${youtubeId}/0.jpg)`;

  return (
    <div
      className="video"
      style={{
        backgroundImage: youtubeCoverImage,
      }}
      tabIndex={0}
      onClick={togglePlay}
      onKeyDown={togglePlay}
      role="button"
      aria-label={playButtonAriaLabel}
      data-testid="video"
    >
      {isPlaying ? (
        <iframe
          title={videoAlt}
          src={`${videoUrl}?autoplay=1`}
          frameBorder="0"
          allowFullScreen
          allow="autoplay"
          className="video__player"
          width="100%"
          height="100%"
        />
      ) : (
        <>
          {coverImage ? (
            <Image className="video__cover-image" imageData={coverImage} alt={videoAlt} />
          ) : null}
          <button
            aria-label={playButtonAriaLabel}
            className="video__play-button"
            onClick={togglePlay}
            type="button"
          >
            <i className="fas fa-play" />
          </button>
        </>
      )}
    </div>
  );
};

export default Video;
