import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import './ImageWithText.scss';

const ImageWithText: FC<PageContent.ImageWithTextType> = ({ image, customText }) => (
  <div className="image-with-text" data-testid="imageWithText">
    <Image imageData={image} alt={image.altText} />
    <DangerouslySetInnerHtml className="image-with-text__custom-text" html={customText} />
  </div>
);

export default ImageWithText;
