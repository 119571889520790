import React, { FC } from 'react';
import classNames from 'classnames';

import Image from 'common/Image';

import './ImageSection.scss';

const ImageSection: FC<PageContent.ImageSection> = ({ mainImage, secondaryImage }) => (
  <section
    className={classNames('image-section', {
      'image-section--dual-image': secondaryImage,
    })}
  >
    <Image imageData={mainImage} alt={mainImage.altText} className="image-section__main-image" />

    {secondaryImage ? (
      <Image
        imageData={secondaryImage}
        alt={secondaryImage.altText}
        className="image-section__secondary-image"
      />
    ) : null}
  </section>
);

export default ImageSection;
