import React, { FC, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';

import Image from 'common/Image';

import { ScrollCarouselProps } from './models';

import './scrollCarousel.scss';

const ScrollCarousel: FC<ScrollCarouselProps> = ({
  carouselImages: { carouselItems },
  className,
}) => {
  const carousel = useRef<HTMLDivElement>(null);
  const carouselItem = useRef<HTMLDivElement>(null);

  const scrollToNextPage = () => {
    const scrollerSize = carouselItem.current!.clientWidth;

    carousel.current!.scrollBy({
      top: 0,
      left: scrollerSize,
      behavior: 'smooth',
    });
  };

  const scrollToPrevPage = () => {
    const scrollerSize = carouselItem.current!.clientWidth;

    carousel.current!.scrollBy({
      top: 0,
      left: -scrollerSize,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <section id="carousel-section" className="carousel-section container">
        <div
          ref={carousel}
          id="scroll-carousel"
          data-testid="scroll-carousel"
          className={classNames('scroll-carousel', { className })}
        >
          <div className="scroll-carousel__content">
            <div className="scroll-carousel__row">
              {carouselItems
                .filter((_, i) => i < Math.round(carouselItems.length / 2))
                .map(({ image, url: imageUrl }) => (
                  <div ref={carouselItem} className="scroll-carousel__item" key={image.altText}>
                    <Link to={imageUrl!.url}>
                      <Image key={image.fallbackUrl} imageData={image} alt={image.altText} />
                    </Link>
                  </div>
                ))}
            </div>
            <div className="scroll-carousel__row">
              {carouselItems
                .filter((_, i) => i >= Math.round(carouselItems.length / 2))
                .map(({ image, url: imageUrl }) => (
                  <div className="scroll-carousel__item" key={image.altText}>
                    <Link to={imageUrl!.url}>
                      <Image key={image.fallbackUrl} imageData={image} alt={image.altText} />
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="container scroll-buttons__container">
        <div className="scroll-buttons__buttons">
          <button
            type="button"
            aria-label="Previous"
            onClick={() => scrollToPrevPage()}
            className="btn prev"
          />
          <button
            type="button"
            aria-label="Next"
            onClick={() => scrollToNextPage()}
            className="btn next"
          />
        </div>
      </div>
    </>
  );
};

export const MultipleRowsCarouselItemsType = graphql`
  fragment MultipleRowsCarouselItemsType on MultipleRowsCarouselItemsType {
    url {
      url
    }
    image {
      ...UmbracoImage
    }
  }
`;

export default ScrollCarousel;
