import React, { FC } from 'react';
import { navigate } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import ImageSection from 'common/ImageSection';
import ImageWithText from 'common/ImageWithText';
import QuoteSection from 'common/QuoteSection';
import Video from 'common/Video';

import { AdditionalInfoProps } from './models';

import './AdditionalInfo.scss';

const AdditionalInfo: FC<AdditionalInfoProps> = ({ content, sectionTitle, isMainPageTitle }) => (
  <div className="additional-info container">
    {sectionTitle ? (
      <>
        {isMainPageTitle ? (
          <h1 className="additional-info__title">{sectionTitle}</h1>
        ) : (
          <h2 className="additional-info__title">{sectionTitle}</h2>
        )}
      </>
    ) : null}
    <div className="additional-info__content">
      {content?.map((item) => {
        const { structure, button, rteText, image, video, mainImage, secondaryImage, quote } = item;

        switch (structure) {
          case 'Additional Info RTE':
            return (
              <DangerouslySetInnerHtml
                key={rteText.text}
                className="additional-info__rte"
                html={rteText.text}
              />
            );
          case 'Additional Info Button':
            return (
              <Button
                key={button.ariaLabel}
                className="btn--sign-up"
                ariaLabel={button.ariaLabel}
                backgroundColor={button.backgroundColor}
                size={button.size}
                type="button"
                onClick={() => navigate(button.link!.url)}
                label={button.label}
              />
            );
          case 'Additional Info Image':
            return (
              <ImageWithText
                key={image.image.fallbackUrl}
                image={image.image}
                customText={image.customText}
              />
            );
          case 'Additional Info Video':
            return (
              <Video
                coverImage={video.coverImage}
                videoUrl={video.videoUrl}
                videoAlt={video.videoAlt}
                playButtonAriaLabel={video.playButtonAriaLabel}
              />
            );
          case 'Additional Info Images':
            return <ImageSection mainImage={mainImage} secondaryImage={secondaryImage} />;
          case 'Additional Info Quote':
            return <QuoteSection quote={quote} />;
          default:
            return null;
        }
      })}
    </div>
  </div>
);

export default AdditionalInfo;
