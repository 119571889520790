import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Carousel from 'common/Carousel';
import PageSchema from 'common/PageSchema';
import ScrollCarousel from 'common/ScrollCarousel';
import Seo from 'common/Seo';
import AdditionalInfo from 'components/AdditionalInfo';
import InfoBanner from 'components/InfoBanner';

import './PartnersPage.scss';

const PartnersPage: FC<PartnersPageTypes.PartnersPageProps> = ({
  data: {
    partnersPage: {
      urls,
      seo,
      mainCarousel,
      additionalInfoSection,
      multipleRowsCarousel,
      infoBanner,
    },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout headerTransparent className="partners-page">
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {mainCarousel ? (
        <Carousel
          {...{
            carouselImages: mainCarousel,
            className: 'main-carousel',
          }}
        />
      ) : null}
      <AdditionalInfo {...additionalInfoSection} />
      {multipleRowsCarousel ? (
        <ScrollCarousel
          {...{
            carouselImages: multipleRowsCarousel,
            className: 'main-carousel main-carousel--multiple-rows',
          }}
        />
      ) : null}
      <InfoBanner {...{ infoBanner }} />
    </Layout>
  );
};

export const query = graphql`
  query PartnersQuery($lang: String) {
    partnersPage(lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      mainCarousel {
        carouselItems {
          ...CarouselItemsType
        }
      }
      multipleRowsCarousel {
        carouselItems {
          ...MultipleRowsCarouselItemsType
        }
      }
      additionalInfoSection {
        ...AdditionalInfoType
      }
      infoBanner {
        ...InfoBannerTypeFragment
      }
    }
  }
`;

export default PartnersPage;
