import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';

import './QuoteSection.scss';

const QuoteSection: FC<PageContent.QuoteSection> = ({ quote }) => (
  <DangerouslySetInnerHtml html={quote} className="quote-section" />
);

export default QuoteSection;
